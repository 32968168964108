import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import MarkdownWrapper from '../../../../components/MarkdownWrapper';
import Layout from '../../../../components/Layout';
import PageChildren from '../../../../components/PageChildren';
export const _frontmatter = {
  "title": "AWS Index",
  "path": "/knowledge/tech/aws",
  "date": "2023-01-31T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout title={props.pageContext.frontmatter.title} location={props.path} mdxType="Layout">
      <MarkdownWrapper mdxType="MarkdownWrapper">
        <h1 {...{
          "id": "aws--index",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h1" {...{
            "href": "#aws--index",
            "aria-label": "aws  index permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`AWS — Index`}</h1>
        <h2 {...{
          "id": "pages",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#pages",
            "aria-label": "pages permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Pages`}</h2>
        <PageChildren location={props} mdxType="PageChildren" />
        <h2 {...{
          "id": "resources",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h2" {...{
            "href": "#resources",
            "aria-label": "resources permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`Resources`}</h2>
        <p>{`Links from the resources in all AWS documents.`}</p>
        <h3 {...{
          "id": "aws-cli",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#aws-cli",
            "aria-label": "aws cli permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`AWS CLI`}</h3>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/cli/latest/userguide/cli-configure-quickstart.html#cli-configure-quickstart-creds"
            }}>{`Configuring the CLI`}</a></li>
        </ul>
        <h3 {...{
          "id": "aws-api-gateway",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#aws-api-gateway",
            "aria-label": "aws api gateway permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`AWS API Gateway`}</h3>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/apigateway/latest/developerguide/welcome.html"
            }}>{`Main docs`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/apigateway/latest/developerguide/http-api-vs-rest.html"
            }}>{`REST API VS HTTP API`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/apigateway/latest/developerguide/models-mappings.html#models-mappings-mappings"
            }}>{`Mapping templates & Models`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.any-api.com/amazonaws_com/apigateway/docs/Definitions/MethodResponse"
            }}>{`Method response type`}</a></li>
        </ul>
        <h3 {...{
          "id": "aws-cdk",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#aws-cdk",
            "aria-label": "aws cdk permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`AWS CDK`}</h3>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/cdk/v2/guide/getting_started.html#getting_started_concepts"
            }}>{`AWS CDK — Getting Started`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.tinystacks.com/blog-post/aws-cdk-vs-cloudformation/"
            }}>{`CloudFormation vs CDK`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://bobbyhadz.com/blog/aws-cdk-http-api-apigateway-v2-example"
            }}>{`API Gateway example`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://bobbyhadz.com/blog/aws-cdk-typescript-lambda"
            }}>{`Lambda Function Example`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/aws-samples/aws-cdk-examples/tree/master/typescript"
            }}>{`TypeScript examples`}</a></li>
        </ul>
        <h3 {...{
          "id": "aws-appconfig",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#aws-appconfig",
            "aria-label": "aws appconfig permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`AWS AppConfig`}</h3>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/appconfig/latest/userguide/what-is-appconfig.html"
            }}>{`Userguide`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.youtube.com/watch?v=sq2HcRMLaLU"
            }}>{`Lambda Function Feature Flags`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://aws.amazon.com/blogs/aws/safe-deployment-of-application-configuration-settings-with-aws-appconfig/"
            }}>{`AWS Blog about setting up AppConfig and its benefits`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-appconfigdata/index.html"
            }}>{`AWS SDK API`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/appconfig/2019-10-09/APIReference/API_GetConfiguration.html"
            }}>{`Deprecated GetConfiguration`}</a></li>
        </ul>
        <h3 {...{
          "id": "aws-iam",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h3" {...{
            "href": "#aws-iam",
            "aria-label": "aws iam permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`AWS IAM`}</h3>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://bobbyhadz.com/blog/aws-cdk-iam-principal#service-principal-example-in-aws-cdk"
            }}>{`IAM Principal Examples in CDK`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/IAM/latest/UserGuide/intro-structure.html"
            }}>{`Understanding IAM`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.amazonaws.cn/en_us/IAM/latest/UserGuide/using-service-linked-roles.html"
            }}>{`service-linked role vs service role`}</a></li>
        </ul>
      </MarkdownWrapper>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      